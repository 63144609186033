import {
  parseISO,
  format,
  add,
  formatISO,
  isDate,
  formatRelative,
  startOfDay,
  endOfDay,
  addMinutes,
} from 'date-fns'
import { toZonedTime, fromZonedTime, formatInTimeZone } from 'date-fns-tz'

import { RsvDow } from '~common/generated/admin-graphql'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-require-imports
const { enUS } = require('date-fns/locale/en-US') // keep is as require remix doesn't support common js imports

export const dow = [
  RsvDow.Mon,
  RsvDow.Tue,
  RsvDow.Wed,
  RsvDow.Thu,
  RsvDow.Fri,
  RsvDow.Sat,
  RsvDow.Sun,
]

const convert = (date: Date | string) => {
  return typeof date === 'string' ? new Date(date) : date
}
export const timeFormat = (date: Date | string) => {
  return format(convert(date), 'h:mmaaa')
}

export const dateFormat = (date: Date | string) => {
  return format(convert(date), 'yyyy-MM-dd')
}

export const dateFormatUs = (date: Date | string) => {
  return format(convert(date), 'MM-dd-yyyy')
}

export const dayFormat = (date: Date | string) => {
  return format(convert(date), 'EEEE')
}

export const prettyDateFormat = (date: Date | string, isCompact?: boolean) => {
  return isCompact
    ? format(convert(date), 'MMM d, yyyy')
    : format(convert(date), 'EEE, MMM d, yyyy')
}

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
  const date = new Date(dateString)
  return date.toLocaleString('en-US', options)
}

export const userDateFormat = (date: Date | string) => {
  return format(convert(date), 'MMM dd, yyyy')
}
/**
 * parses date string to timezone relative date object
 */
export const parseISODateString = (
  isoDateString: string,
  timezone?: string,
): Date => {
  if (timezone) {
    return toZonedTime(isoDateString, timezone)
  }
  return parseISO(isoDateString)
}

export const parseISODateToUTC = (
  isoDateString: string,
  timezone?: string,
  toLocal?: boolean,
) => {
  let date
  if (timezone) {
    if (toLocal) {
      return toZonedTime(isoDateString, timezone)
    }
    return toZonedTime(isoDateString, timezone, { timeZone: timezone })
  } else {
    date = parseISO(isoDateString)
  }

  const localTimezoneOffset = date.getTimezoneOffset()

  return add(date, { minutes: localTimezoneOffset })
}

export const formatISODateTime = (date: string | Date): string => {
  if (isDate(date)) {
    return formatISO(date)
  }
  return formatISO(parseISODateString(date))
}

const calcZonedDate = (
  date: string | number | Date,
  tz: string,
  fn: any,
  options: any = null,
) => {
  const inputZoned = toZonedTime(date, tz)
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned)
  return fromZonedTime(fnZoned, tz)
}

export const getZonedStartOfDay = (
  date: string | number | Date,
  timeZone: string,
) => {
  return calcZonedDate(date, timeZone, startOfDay)
}

export const getZonedEndOfDay = (
  date: string | number | Date,
  timeZone: string,
) => {
  return calcZonedDate(date, timeZone, endOfDay)
}

export const relativeTime = (date: string | number | Date) => {
  return formatRelative(date, new Date())
}

export const getClassTime = (
  time: string,
  duration: number,
  timezone?: string,
) => {
  const currentTime = parseISODateToUTC(`2023-01-01T${time}`, timezone)
  const laterTime = addMinutes(currentTime, duration)
  return `${format(currentTime, 'h:mmaaa')} - ${format(laterTime, 'h:mmaaa')}`
}

export const getOffsetName = (time: string, timezone: string) => {
  const currentTime = parseISODateToUTC(`2023-01-01T${time}`, timezone)
  return formatInTimeZone(currentTime, timezone, 'zzz', {
    locale: enUS,
  })
}
